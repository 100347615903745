@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.root {
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: -100%;
  opacity: 0;
  position: fixed;
  top: 0;
  transform: translateX(0);
  pointer-events: auto;
  width: 100%;
  will-change: opacity, transform;
  z-index: 300;

  &Nested {
    z-index: 305;
  }

  &.open {
    opacity: 1;

    &.transitionInFx {
      transform: translateX(100%);
    }
  }

  &.transitionInFx {
    transition: opacity 150ms ease-in;
  }

  &.transitionOutFx {
    transform: translateX(100%);
    transition: opacity 150ms ease-out;
  }
}

.drawer {
  background: colors.$background;
  display: flex;
  flex-flow: column;
  height: 100%;
  position: fixed;
  top: 0;
  touch-action: none;
  transform: translateX(0);
  width: 100%;
  will-change: transform;
  z-index: 300;
  
  &Nested {
    z-index: 305;
  }

  @media screen and (min-width: vars.$medium) {
    width: 50%;
  }

  @media screen and (min-width: vars.$extra-large) {
    width: 30%;
  }

  &.open {
    opacity: 1;
    transform: translateX(100%);
  }

  &.transitionInFx {
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);
  }

  &.transitionOutFx {
    transition: transform 150ms cubic-bezier(0.4, 0, 1, 1);
  }

  header {
    align-items: center;
    background: colors.$white;
    border-bottom: solid 1px colors.$grey;
    display: flex;
    font-stretch: condensed;
    font-weight: bold;
    min-height: 45px;
    height: 45px;
    justify-content: center;
    position: relative;
    font-size: 21px;
    margin-left: -10px;
    width: calc(100% + 20px);

    @media screen and (min-width: vars.$medium) {
      margin-left: -15px;
      width: calc(100% + 15px);
    }
    @media screen and (min-width: vars.$extra-large) {
      background-color: transparent;
      font-size: 2rem;
      justify-content: flex-start;
      margin: 0;
      padding: 45px 0;
      width: initial;
    }

    h3 {
      text-align: center;
      overflow: hidden;
      width: 80%;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    button {
      background-color: initial;
      border: initial;
      color: colors.$secondary-text;
      position: absolute;
      left: 15px;

      &:last-of-type {
        left: initial;
        right: 15px;
      }
    }
  }

  section {
    display: flex;
    flex: 1 1 auto;
    flex-flow: column;
    overflow: auto;
  }

  footer {
    align-items: center;
    background: colors.$white;
    display: flex;
    flex: 0 0 60px;
    flex-direction: row;
    column-gap: 15px;
  }
}

.directionLeftToRight {
  left: -100%;

  @media screen and (min-width: vars.$medium) {
    left: -50%;
  }

  @media screen and (min-width: vars.$extra-large) {
    left: -30%;
  }
}

.directionRightToLeft {
  right: -100%;

  @media screen and (min-width: vars.$medium) {
    right: -50%;
  }

  @media screen and (min-width: vars.$extra-large) {
    right: -30%;
  }
}

.directionBottomToTop {
  bottom: -100%;

  @media screen and (min-width: vars.$medium) {
    bottom: -50%;
  }

  @media screen and (min-width: vars.$extra-large) {
    bottom: -30%;
  }
}

.directionTopToBottom {
  top: -100%;

  @media screen and (min-width: vars.$medium) {
    top: -50%;
  }

  @media screen and (min-width: vars.$extra-large) {
    top: -30%;
  }
}

.open {
  &.directionLeftToRight {
    transform: translateX(100%);
  }

  &.directionRightToLeft {
    transform: translateX(-100%);
  }

  &.directionBottomToTop {
    transform: translateY(-100%);
  }

  &.directionTopToBottom {
    transform: translateY(100%);
  }
}
